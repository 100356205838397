import React, { useState } from "react";
import "./Service.css";
import OrderButton from "../../Components/Scene/Order/OrderButton";

// Import gambar untuk layanan
import pemeriksaanAwalImg1 from "../../Asset2/armada 3.png";
import pemeriksaanAwalImg2 from "../../Asset2/armada.png";
import pemeriksaanAwalImg3 from "../../Asset2/pengecekan 2.png";
import pemeriksaanAwalImg4 from "../../Asset2/armada 2.png";

import penyedotanSp1 from "../../Asset2/penyedotan sepitank 2.png";
import penyedotanSp2 from "../../Asset2/pdtn 2.jpg";
import penyedotanSp3 from "../../Asset2/penyedotan 3.jpg";
import penyedotanSp4 from "../../Asset2/penyedotan 6.jpg";
import penyedotanSp5 from "../../Asset2/pengurasan.jpg";
import penyedotanSp6 from "../../Asset2/penyedotan sepitank.png";
import penyedotanSp7 from "../../Asset2/Penyedotan 7.jpg";
import saluranP1 from "../../Asset2/saluran.png";
import saluranP2 from "../../Asset2/pelancaran saluran 2.jpg";
import saluranP3 from "../../Asset2/pelancaran saluran.jpg";
import saluranP4 from "../../Asset2/pengurasan 2.jpg";
import saluranP5 from "../../Asset2/armada (2).png";
import saluranP6 from "../../Asset2/armada 4.png";
import limbahPabrikResto1 from "../../Asset2/penyedotan limbah .jpg";
import limbahPabrikResto2 from "../../Asset2/penyedotan limbah 2.jpg";
import limbahPabrikResto3 from "../../Asset2/penyedotan 2.jpg";
import limbahPabrikResto4 from "../../Asset2/limbah pabrik.jpg";

const Services = () => {
  const [currentIndex, setCurrentIndex] = useState({
    pemeriksaanAwal: 0,
    penyedotanSp: 0,
    saluranAir: 0,
    limbahPabrikResto: 0,
  });

  const images = {
    pemeriksaanAwal: [
      pemeriksaanAwalImg1,
      pemeriksaanAwalImg2,
      pemeriksaanAwalImg3,
      pemeriksaanAwalImg4,
    ],
    penyedotanSp: [
      penyedotanSp1,
      penyedotanSp2,
      penyedotanSp3,
      penyedotanSp4,
      penyedotanSp5,
      penyedotanSp6,
      penyedotanSp7,
    ],
    saluranAir: [
      saluranP1,
      saluranP2,
      saluranP3,
      saluranP4,
      saluranP5,
      saluranP6,
    ],
    limbahPabrikResto: [
      limbahPabrikResto1,
      limbahPabrikResto2,
      limbahPabrikResto3,
      limbahPabrikResto4,
    ],
  };

  const handleChange = (type, direction) => {
    setCurrentIndex((prevState) => {
      const currentIndex = prevState[type];
      const newIndex =
        direction === "next"
          ? (currentIndex + 1) % images[type].length
          : (currentIndex - 1 + images[type].length) % images[type].length;

      return {
        ...prevState,
        [type]: newIndex,
      };
    });
  };

  return (
    <div className="services">
      <div className="services-content">
        <h1>Daftar Pekerjaan dalam Layanan Sedot WC Toiletid</h1>
        <div className="service-list">
          <div className="service-item">
            <h2>Pemeriksaan Awal</h2>
            <div className="service-image-container">
              <button
                className="arrow-button left"
                onClick={() => handleChange("pemeriksaanAwal", "prev")}
              >
                &#9664; {/* Left arrow */}
              </button>
              <img
                src={images.pemeriksaanAwal[currentIndex.pemeriksaanAwal]}
                alt="Pemeriksaan Awal"
                className="service-image"
              />
              <button
                className="arrow-button right"
                onClick={() => handleChange("pemeriksaanAwal", "next")}
              >
                &#9654; {/* Right arrow */}
              </button>
            </div>
            <p>
              Melakukan penilaian kondisi tangki septik dan sistem pembuangan
              limbah.
            </p>
          </div>
          <div className="service-item">
            <h2>Penyedotan Septik Tank</h2>
            <div className="service-image-container">
              <button
                className="arrow-button left"
                onClick={() => handleChange("penyedotanSp", "prev")}
              >
                &#9664; {/* Left arrow */}
              </button>
              <img
                src={images.penyedotanSp[currentIndex.penyedotanSp]}
                alt="Penyedotan Limbah"
                className="service-image"
              />
              <button
                className="arrow-button right"
                onClick={() => handleChange("penyedotanSp", "next")}
              >
                &#9654; {/* Right arrow */}
              </button>
            </div>
            <p>
              Menyedot limbah dari tangki septik menggunakan peralatan modern.
            </p>
          </div>
          <div className="service-item">
            <h2>Permasalahan Saluran Air</h2>
            <div className="service-image-container">
              <button
                className="arrow-button left"
                onClick={() => handleChange("saluranAir", "prev")}
              >
                &#9664; {/* Left arrow */}
              </button>
              <img
                src={images.saluranAir[currentIndex.saluranAir]}
                alt="Saluran Air"
                className="service-image"
              />
              <button
                className="arrow-button right"
                onClick={() => handleChange("saluranAir", "next")}
              >
                &#9654; {/* Right arrow */}
              </button>
            </div>
            <p>Mengatasi masalah pada saluran air dengan cepat dan efektif.</p>
          </div>
          <div className="service-item">
            <h2>Penyedotan Limbah Pabrik dan Resto</h2>
            <div className="service-image-container">
              <button
                className="arrow-button left"
                onClick={() => handleChange("limbahPabrikResto", "prev")}
              >
                &#9664; {/* Left arrow */}
              </button>
              <img
                src={images.limbahPabrikResto[currentIndex.limbahPabrikResto]}
                alt="Penyedotan Limbah Pabrik dan Resto"
                className="service-image"
              />
              <button
                className="arrow-button right"
                onClick={() => handleChange("limbahPabrikResto", "next")}
              >
                &#9654; {/* Right arrow */}
              </button>
            </div>
            <p>
              Menyediakan layanan penyedotan limbah khusus untuk pabrik dan
              restoran dengan standar kebersihan tinggi.
            </p>
          </div>
        </div>
        <h2>Hubungi Kami Sekarang!</h2>
        <p>
          Jangan biarkan masalah WC mengganggu kenyamanan Anda. Hubungi TOILETID
          sekarang untuk mendapatkan layanan sedot WC profesional dan
          terpercaya. Kami siap membantu Anda 24/7 dengan layanan terbaik dan
          harga terjangkau.
          <br />
          <br />
          TOILETID - Solusi Terbaik untuk Kebutuhan Toilet dan Sedot WC Anda!
        </p>
        <OrderButton />
      </div>
    </div>
  );
};

export default Services;
